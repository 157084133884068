import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ChargerDtoV2 } from '../dto/charger.dto';
import PricingList from './pricing-list.component';
import { getDurationCount, getRateCount } from './utils';

const styles = {
  listWrapper: {
    marginLeft: '-15px',
  },
};

interface TieredPricingInterface {
  classes: ClassNameMap;
  charger: ChargerDtoV2;
  hasUserDiscount: boolean;
}

const TieredPricing = ({
  classes,
  charger,
  hasUserDiscount,
}: TieredPricingInterface) => {
  const { tierPrices } = charger?.priceSchedule;
  const { i18n } = useTranslation();

  return (
    <>
      <PricingList column1={i18n.t('charging')} active />
      {tierPrices?.map((pricePeriod, i) => {
        const {
          duration,
          unitPrice,
          pricingType,
          currency,
          durationUnit,
          priceBeforeAdjustment,
        } = pricePeriod;
        return (
          <PricingList
            column1={getDurationCount(
              duration,
              pricingType,
              i,
              durationUnit,
              i18n,
            )}
            column2={
              unitPrice === 0
                ? i18n.t('free')
                : getRateCount(unitPrice, pricingType, currency)
            }
            column3={getRateCount(
              priceBeforeAdjustment?.unitPrice,
              pricingType,
              currency,
            )}
            active={i === 0} // for first hours column
            hasUserDiscount={hasUserDiscount}
            styles={{ marginTop: '0px' }}
          />
        );
      })}
    </>
  );
};

export default withStyles(styles)(TieredPricing);
