import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { NUMBER } from '../../constant/number.constant';

export const enum DISCOUNT_PILL_TYPES {
  FREE,
  PERCENTAGE,
}

const userStyles = makeStyles(() => ({
  pillContainer: {
    display: 'flex',
    padding: '2px 8px',
    alignItems: 'center',
    borderRadius: '30px',
    background: '#F5FAF0',
    color: '#558B2F',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    marginLeft: '8px',
  },
}));

interface DiscountPillProps {
  userPriceAdjustment: {
    value: number;
  };
}

const DiscountPill = ({ userPriceAdjustment }: DiscountPillProps) => {
  const classes = userStyles();
  const { t } = useTranslation();

  const renderDiscountPill = () => {
    if (userPriceAdjustment.value === NUMBER.HUNDRED) {
      return <div className={classes.pillContainer}>{t('free')}</div>;
    }
    return (
      <div className={classes.pillContainer}>{`${
        userPriceAdjustment.value
      }% ${t('discount_off_label')}`}</div>
    );
  };

  return renderDiscountPill();
};

export default DiscountPill;
