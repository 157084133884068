import moment from 'moment';
import {
  DurationUnit,
  PricingType,
  ScheduleType,
} from '../../../stores/types/price.interface';
import { format2Digits } from '../../../util/numbers-utility';
import getSymbolFromCurrency from 'currency-symbol-map';
import { i18n } from '../../../services/translation/i18n';
import { NUMBER } from '../../../constant/number.constant';
import { sessionPricingType } from '../charger-session/utils';
// getPricingType convert selected price unit key into view string

export const getPricingType = (type: string) => {
  let response;
  switch (type) {
    case PricingType.BILLED_BY_EFFECTIVE_CHARGING_TIME:
      response = i18n.t('price_charging_label');
      break;
    case PricingType.BILLED_BY_TIME_PLUGGED_IN:
      response = i18n.t('receipt_plugged_in');
      break;
    case PricingType.BILLED_BY_KWH:
      response = '';
      break;
    default:
      response = i18n.t('receipt_plugged_in');
      break;
  }
  return response;
};

// getDurationCount it modifies tier duration into view string

const convertDurationUnits = (durationUnit: string, duration: number) => {
  let response = 'hour';
  if (durationUnit === DurationUnit.JOULES) {
    response = 'kWh';
  }
  if (duration > 1 && durationUnit !== DurationUnit.JOULES) {
    response += 's';
  }
  return response;
};
// Converts duration based on durationUnit in either hour or kilowatthours
const convertDuration = (duration: number, durationUnit: string) => {
  if (durationUnit === DurationUnit.SECONDS) {
    return duration / 3600;
  }
  if (durationUnit === DurationUnit.JOULES) {
    return duration / 3600000;
  }
  return duration;
};

export const getDurationCount = (
  duration: number,
  pricingType: string,
  index: number,
  durationUnit: string,
  i18n: any,
) => {
  let tierNumber = i18n.t('next');
  let durationStr = i18n.t('afterwards');
  if (index === 0) {
    tierNumber = i18n.t('first');
  }
  if (duration) {
    const convertedDuration = convertDuration(duration, durationUnit);
    durationStr = `${i18n.t(
      'for',
    )} ${tierNumber} ${convertedDuration} ${convertDurationUnits(
      durationUnit,
      convertedDuration,
    )} `;
  }
  return durationStr;
};

//convertRateUnits converts HOUR => hr and KWH => Kwh

const convertRateUnits = (unit: string) => {
  let response = 'hr';
  if (unit === PricingType.BILLED_BY_KWH) {
    response = 'kWh';
  }
  return response;
};

// change '$1.0' => '$1.00'
export const formatPrice = (price: number, currency: string) => {
  if (price === 0) return '$0.00';
  return `${getSymbolFromCurrency(currency)}${format2Digits(price)}`;
};

// getRateCount show price and duration unit into view with pricingType

export const getRateCount = (
  price: number,
  pricingType: string,
  currency: string,
) => {
  return `${formatPrice(price, currency)}/${convertRateUnits(
    pricingType,
  )} ${getPricingType(pricingType)}`;
};

export const getRateCountWithoutType = (price: number, currency: string) => {
  return `${formatPrice(price, currency)}`;
};
export const getTimeinAMPMFormat = (time: string) => {
  const startTime = moment(time, 'YYYY-MM-DDTHH:mm:ssZ').toDate();
  return moment(startTime).format('hh:mm A');
};

// sort pricing accoding to weekdays

export const weekDays = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

//Convert 24 hour format to 12 hour format
export const formatTime = (time: string) => {
  return moment(time, 'HH:mm').format('h:mm A');
};

export const mergeDayPricing = (days) => {
  const obj = {};

  days.forEach((element) => {
    obj[element.day] = element.pricePeriods;
  });

  const data = Object.keys(weekDays).map((item) => {
    if (obj[item.toUpperCase()]) {
      return { day: item, pricePeriods: obj[item.toUpperCase()] };
    } else {
      return { day: item, pricePeriods: i18n.t('free') };
    }
  });

  return data;
};

export const convertDaysArrayToString = (days) => {
  return days.map((day) => day.substring(0, 3)).join(', ');
};

export const formatDays = (day: string, type: string) => {
  if (type === ScheduleType.WEEKDAYS_WEEKEND) {
    return day === 'MON_FRI' ? i18n.t('weekday') : i18n.t('weekends');
  }
  if (type === ScheduleType.SAME_EVERYDAY) {
    return i18n.t('daily');
  }
  return day;
};

export const formatPricingTime = (startTime: string, endTime: string) => {
  if (
    moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'hours') ===
    NUMBER.TWENTY_FOUR
  ) {
    return i18n.t('all_day');
  }

  return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};

export const getIdleRate = (price: number, currency: string) => {
  return `${getRateCountWithoutType(price, currency)}/${sessionPricingType(
    PricingType.IDLE,
  )}`;
};
