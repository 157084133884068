import React from 'react';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import { useTranslation } from 'react-i18next';
import { emailStyles } from '../edit-email/styles';
import ActionButton from '../action-button.component';
import { FormLabel, Grid, Typography } from '@mui/material';
import { UserMode } from '../types/user-mode.enum';
import CustomPhoneInput from '../../react-native-material-wrapper/phone-input.component';
import { parsePhoneNumberWithPlus } from '../../../util/js-utility';
import { isValidPhoneNumber } from 'react-phone-number-input';
import clsx from 'clsx';

interface IProps {
  isCurrentPhone: boolean;
  phone: string;
  setView: Function;
  setVerifyOtp: Function;
  setPhone: Function;
  handleSubmitEmail: Function;
  isPhoneVerified: boolean;
}

const PhoneForm = ({
  isCurrentPhone,
  phone,
  setPhone,
  setView,
  handleSubmitEmail,
  isPhoneVerified,
}: IProps) => {
  const { i18n } = useTranslation();

  const classes = emailStyles();

  const isPhoneValid = isValidPhoneNumber(parsePhoneNumberWithPlus(phone));

  const onClickBack = () => {
    setView(UserMode.NONE);
  };

  const showMessageBasedOnConditions = () => {
    if (isPhoneVerified && isCurrentPhone) {
      return i18n.t('verified');
    } else if (!isPhoneVerified && isCurrentPhone) {
      return i18n.t('not_verified');
    } else return '';
  };

  return (
    <>
      <BackButtonHeader
        title={i18n.t('phone_number')}
        onBackArrowClicked={onClickBack}
      />
      <Grid className={classes.formWrapper}>
        <FormLabel htmlFor='phone_number' className={classes.title}>
          {i18n.t('phone_number')}
        </FormLabel>
        <Grid className={clsx(classes.displayRelative, classes.marginTop8)}>
          <CustomPhoneInput
            id='phone_number'
            inputProps={{
              required: true,
              'aria-label': i18n.t('canada_selected'),
            }}
            country={'ca'}
            value={phone}
            onChange={(e) => setPhone(e)}
            dropdownStyle={{
              width: '240px',
              background: '#F2F4F6',
            }}
          />
          {phone.length ? (
            isPhoneValid ? (
              <>
                <Grid
                  className={clsx(classes.labelPosition, classes.inputLabel, {
                    [classes.verifiedMessage]: isPhoneVerified,
                    [classes.notVerifiedMessage]: !isPhoneVerified,
                  })}
                >
                  {showMessageBasedOnConditions()}
                </Grid>
                {!isPhoneVerified && isCurrentPhone && (
                  <Typography
                    className={clsx(classes.helpText, classes.marginTop8)}
                  >
                    {i18n.t('phone_verification_is_required_to_login')}
                  </Typography>
                )}
              </>
            ) : (
              !isPhoneValid && (
                <Typography
                  className={clsx(
                    classes.helpText,
                    classes.errorMessage,
                    classes.marginTop8,
                  )}
                >
                  {i18n.t('phone_number_invalid')}
                </Typography>
              )
            )
          ) : null}
        </Grid>
      </Grid>
      <ActionButton
        label={i18n.t('continue')}
        action={handleSubmitEmail}
        isDisabled={!isPhoneValid || isCurrentPhone}
      />
    </>
  );
};

export default PhoneForm;
