import React from 'react';
import { BackButtonHeader } from '../../ui-components/back-button-header.component';
import CustomTextField from '../../react-native-material-wrapper/text-field.component';
import { useTranslation } from 'react-i18next';
import { emailStyles } from './styles';
import ActionButton from '../action-button.component';
import { FormLabel, Grid, InputAdornment, Typography } from '@mui/material';
import { UserMode } from '../types/user-mode.enum';
import { validateEmail } from '../../../hooks/utils';
import { errorInfoIcon } from '../../../assets/icons/icons';
import clsx from 'clsx';

interface IProps {
  email: string;
  isCurrentEmail: boolean;
  setView: Function;
  setVerifyOtp: Function;
  setEmail: Function;
  handleSubmitEmail: Function;
  isEmailVerified: boolean;
}

const EmailForm = ({
  email,
  isCurrentEmail,
  setEmail,
  setView,
  setVerifyOtp,
  handleSubmitEmail,
  isEmailVerified,
}: IProps) => {
  const { i18n } = useTranslation();

  const isEmailValid = validateEmail(email);

  const classes = emailStyles();

  const onClickBack = () => {
    setView(UserMode.NONE);
  };

  const showMessageBasedOnConditions = () => {
    if (isEmailVerified && isCurrentEmail) {
      return i18n.t('verified');
    } else if (!isEmailVerified && isCurrentEmail) {
      return i18n.t('not_verified');
    } else return '';
  };

  return (
    <>
      <BackButtonHeader
        title={i18n.t('email')}
        onBackArrowClicked={onClickBack}
      />
      <Grid className={classes.formWrapper}>
        <FormLabel htmlFor='email' className={classes.title}>
          {i18n.t('email')}
        </FormLabel>
        <CustomTextField
          id='email'
          value={email}
          style={{ width: '100%' }}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={i18n.t('email')}
          variant='outlined'
          required
          autoFocus
          error={!isEmailValid}
          className={clsx(classes.inputWrapper, classes.marginTop8)}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='start'>
                {isEmailValid ? (
                  <Typography
                    className={clsx(classes.inputLabel, {
                      [classes.verifiedMessage]: isEmailVerified,
                      [classes.notVerifiedMessage]: !isEmailVerified,
                    })}
                  >
                    {showMessageBasedOnConditions()}
                  </Typography>
                ) : (
                  errorInfoIcon()
                )}
              </InputAdornment>
            ),
          }}
        />
        {isEmailValid ? (
          <Typography className={clsx(classes.helpText, classes.marginTop8)}>
            {!isEmailVerified &&
              isCurrentEmail &&
              i18n.t('email_verification_is_required_to_login')}
          </Typography>
        ) : (
          <Typography
            className={clsx(
              classes.helpText,
              classes.errorMessage,
              classes.marginTop8,
            )}
          >
            {i18n.t('please_enter_a_valid_email')}
          </Typography>
        )}
      </Grid>
      <ActionButton
        label={i18n.t('continue')}
        action={handleSubmitEmail}
        isDisabled={!isEmailValid}
      />
    </>
  );
};

export default EmailForm;
