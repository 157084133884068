import { i18n } from '../../../services/translation/i18n';
import { ScheduleType } from '../../../stores/types/price.interface';
import { ChargerDtoV2, PricePeriodV2 } from '../dto/charger.dto';
import PricingList from './pricing-list.component';
import {
  convertDaysArrayToString,
  formatDays,
  formatPricingTime,
  getRateCount,
  mergeDayPricing,
} from './utils';

interface DayPricingInterface {
  charger: ChargerDtoV2;
  hasUserDiscount: boolean;
}
const DayPricing = ({ charger, hasUserDiscount }: DayPricingInterface) => {
  const { dayPrices, type } = charger?.priceSchedule;

  const renderPeriodView = (day: string, periods: Array<PricePeriodV2>) => (
    <>
      <PricingList
        column1={formatDays(day, type)}
        column2={periods.length === 0 ? i18n.t('free') : ''}
        type='listHead'
        hasUserDiscount={hasUserDiscount}
        active
      />
      {periods?.map((pricePeriod) => {
        const { startTime, endTime, unitPrice, pricingType, currency } =
          pricePeriod;
        return (
          <PricingList
            column1={formatPricingTime(startTime, endTime)}
            column2={getRateCount(unitPrice, pricingType, currency)}
            hasUserDiscount={hasUserDiscount}
            type='scheduleList'
          />
        );
      })}
    </>
  );

  const data = {};

  mergeDayPricing(dayPrices).forEach((item) => {
    // TODO: Improve this
    // I have to added this map, previous comparsion doesn't work because the active value is different.
    const mappedPricePeriods = Array.isArray(item?.pricePeriods)
      ? item?.pricePeriods?.map((pricePeriod: PricePeriodV2) => {
          return {
            startTime: pricePeriod.startTime,
            endTime: pricePeriod.endTime,
            unitPrice: pricePeriod.unitPrice,
            pricingType: pricePeriod.pricingType,
            currency: pricePeriod.currency,
            current: pricePeriod.current,
          };
        })
      : item?.pricePeriods;
    const priceKey = JSON.stringify(mappedPricePeriods);
    if (data[priceKey]) {
      data[priceKey] = [...data[priceKey], i18n.t(item.day.toLowerCase())];
    } else {
      data[priceKey] = [i18n.t(item.day.toLowerCase())];
    }
  });

  const dataKey = Object.keys(data);

  return type === ScheduleType.PER_DAY ? (
    <>
      {dataKey?.map((day) => {
        return JSON.parse(day) !== i18n.t('free') &&
          JSON.parse(day)?.length > 0 ? (
          renderPeriodView(convertDaysArrayToString(data[day]), JSON.parse(day))
        ) : (
          <PricingList
            column1={convertDaysArrayToString(data[day])}
            column2={i18n.t('free')}
            type='scheduleList'
            active
            styles={{
              marginTop: '16px',
            }}
          />
        );
      })}
    </>
  ) : (
    <>{dayPrices?.map((day) => renderPeriodView(day.day, day?.pricePeriods))}</>
  );
};

export default DayPricing;
