import { Grid, Typography } from '@mui/material';
import { ClassNameMap, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ScheduleType } from '../../../stores/types/price.interface';
import DayPricing from './day-pricing.component';
import PricingList from './pricing-list.component';
import { getRateCount } from './utils';
import { ChargerDtoV2 } from '../dto/charger.dto';
import ChevronDown from '../../../assets/icons/chevron_down.svg';
import ChevronUp from '../../../assets/icons/chevron_up.svg';
import { useState } from 'react';

const styles = {
  scheduleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  scheduleText: {
    color: '#202223',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  scheduleContent: {
    padding: '0px 16px 16px',
    marginTop: '16px',
    borderRadius: '12px',
    border: '1px solid #E5E8EB',
  },
  scheduleWarning: {
    color: '#4E5968',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    paddingTop: '16px',
  },
};

interface PricingDetailsInterface {
  classes: ClassNameMap;
  charger: ChargerDtoV2;
  hasUserDiscount: boolean;
}

const PricingDetails = ({
  classes,
  charger,
  hasUserDiscount,
}: PricingDetailsInterface) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { type, dayPrices } = charger?.priceSchedule;
  const { unitPrice, pricingType, currency, priceBeforeAdjustment } =
    charger?.currentPrice;

  const isFullDay = (startTime: string, endTime: string) =>
    startTime === endTime || (startTime === '00:00' && endTime === '24:00');

  const isEveryDayFullDay = () => {
    let str;
    if (type === ScheduleType.SAME_EVERYDAY) {
      const [prices] = dayPrices;
      if (prices?.pricePeriods.length > 0) {
        const [pricePeriod] = prices?.pricePeriods;

        const { unitPrice, pricingType, endTime, startTime, currency } =
          pricePeriod;
        if (isFullDay(startTime, endTime)) {
          str = getRateCount(unitPrice, pricingType, currency);
        }
      }
    }
    return str;
  };

  return (
    <>
      <PricingList
        column1={i18n.t('charging')}
        column2={
          unitPrice === 0
            ? i18n.t('free')
            : getRateCount(unitPrice, pricingType, currency)
        }
        column3={getRateCount(
          priceBeforeAdjustment?.unitPrice,
          pricingType,
          currency,
        )}
        hasUserDiscount={hasUserDiscount}
        active
      />
      {!isEveryDayFullDay() && (
        <Grid
          container
          style={{ marginTop: '24px', cursor: 'pointer' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Grid item xs={12} className={classes.scheduleWrapper}>
            <Typography className={classes.scheduleText}>
              {i18n.t('schedule')}
            </Typography>
            {isOpen ? (
              <img src={ChevronUp} alt='Up' width={24} height={24} />
            ) : (
              <img src={ChevronDown} alt='Down' width={24} height={24} />
            )}
          </Grid>
        </Grid>
      )}
      {isOpen && (
        <>
          <Grid container className={classes.scheduleContent}>
            <Grid item xs={12}>
              <DayPricing charger={charger} hasUserDiscount={hasUserDiscount} />
            </Grid>
          </Grid>
          <Typography className={classes.scheduleWarning}>
            {i18n.t('discount_alert_text')}
          </Typography>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(PricingDetails);
