import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Divider, Button } from '@mui/material';

import { useDomain } from '../../hooks/useDomain';
import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import { ROUTES } from '../../types/routes.enum';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useAddVehicleMutation } from '../../services/plug-and-charge-keys.api';
import { plugAndChargeKeysStyle } from './plug-and-charge-keys-style';
import clsx from 'clsx';
import { LoadingSpinner } from '../ui-components/loading-spinner.component';

export const PlugAndChargeKeysAddVehicleComponent = () => {
  const { i18n } = useTranslation();
  const [PCID, setPCID] = useState('');
  const navigate = useNavigate();
  const { domain } = useDomain();
  const classes = plugAndChargeKeysStyle();
  const theme = useSelector(getTheme);

  const [
    triggerAddVehicle,
    { isSuccess: isAddVehicleSuccess, isLoading: isAddVehicleLoading },
  ] = useAddVehicleMutation();

  useEffect(() => {
    if (isAddVehicleSuccess) {
      navigate(`/app/${domain}/${ROUTES.PLUG_AND_CHARGE_KEYS}`);
    }
  }, [isAddVehicleSuccess]);

  useEffect(() => {
    if (isAddVehicleLoading) {
      LoadingSpinner.show();
    } else {
      LoadingSpinner.hide();
    }
  }, [isAddVehicleLoading]);

  const handleInputChange = (value) => {
    setPCID(value);
  };

  const handleSubmit = () => {
    triggerAddVehicle({
      pcid: PCID,
    });
  };

  const goBackInnavigate = () => {
    navigate(-1);
  };

  return (
    <ViewWrapper>
      <BackButtonHeader
        title={i18n.t('plug_and_charge_add_vehicle')}
        onBackArrowClicked={goBackInnavigate}
      />
      <Box component='div' p={2.5}>
        <Typography
          variant='subtitle1'
          component='span'
          color='textPrimary'
          className={classes.addressConfirmationText}
        >
          {i18n.t('plug_and_charge_enter_pcid')}
        </Typography>
        <Grid item xs={12} className={classes.paddingBottom8}>
          <CustomTextField
            className={clsx(classes.addVehicleInputWrapper, classes.labelB2)}
            fullWidth
            value={PCID}
            onChange={(e) => handleInputChange(e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('plug_and_charge_pcid_placeholder')}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Button
          onClick={() => handleSubmit()}
          className={`${classes.button} ${classes.orderButtonContainer}`}
          style={{
            backgroundColor: theme.navigationSelectedColor,
            color: '#FFF',
          }}
          variant='contained'
          color='primary'
        >
          {i18n.t('submit')}
        </Button>
        <Divider
          style={{
            background: '#F5F5F5',
            marginTop: '24px',
            marginBottom: '10px',
          }}
        />
      </Box>
    </ViewWrapper>
  );
};
