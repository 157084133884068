import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  listWrapper: {
    marginLeft: '-15px',
  },
  list: {
    marginBottom: '8px',
  },
  listHead: {
    marginTop: '16px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#6B7684',
  },
  activeItem: {
    marginTop: '16px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#202223',
  },
  discountContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    justifyContent: 'center',
  },
  discountText: {
    color: '#6B7684',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'right',
    textDecorationLine: 'line-through',
  },
  scheduleList: {
    color: '#6B7684',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

interface IProps {
  column1: string;
  column2?: string;
  column3?: string;
  type?: string;
  active?: boolean;
  hasUserDiscount?: boolean;
  styles?: any;
}

const PricingList = ({
  column1,
  column2,
  column3,
  type,
  active,
  hasUserDiscount,
  styles = {},
}: IProps) => {
  const classes = useStyles();

  // For schedule list type
  if (type === 'scheduleList') {
    return (
      column1 && (
        <Grid
          container
          className={classes.list}
          style={{ marginBottom: '1px' }}
        >
          <Grid item xs={6}>
            {column1 && (
              <Typography
                className={active ? classes.activeItem : classes.scheduleList}
              >
                {column1}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} className={classes.discountContainer}>
            <Typography className={classes.scheduleList} style={{ ...styles }}>
              {column2}
            </Typography>
            {hasUserDiscount && (
              <Typography className={classes.scheduleList}>
                {column3}
              </Typography>
            )}
          </Grid>
        </Grid>
      )
    );
  }

  return (
    column1 && (
      <Grid container className={classes.list}>
        <Grid item xs={6}>
          {column1 && (
            <Typography
              className={active ? classes.activeItem : classes[type]}
              style={{ ...styles }}
            >
              {column1}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.discountContainer}>
          <Typography
            className={active ? classes.activeItem : classes[type]}
            style={{ ...styles }}
          >
            {column2}
          </Typography>
          {hasUserDiscount && (
            <Typography className={classes.discountText}>{column3}</Typography>
          )}
        </Grid>
      </Grid>
    )
  );
};

PricingList.defaultProps = {
  column2: '',
  type: 'listHead',
};

export default PricingList;
