import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormLabel, Grid, Typography } from '@mui/material';
import { Country } from 'country-state-city';
import clsx from 'clsx';

import { rfidStyle } from '../rfidStyle';
import DropdownIcon from '../../../assets/icons/receipt/Dropdown.svg';
import CustomTextField from '../../react-native-material-wrapper/text-field.component';
import { getTheme } from '../../../stores/selectors/theme.selector';

export const OrderCardForm = ({
  mailingDetails,
  countryDropdownVisible,
  countryDropdown,
  handleInputChange,
  provinceDropdownVisible,
  provinceDropdown,
  handleProvinceDropdown,
  handleCountryDropdown,
  isFirstOrder = false,
}) => {
  const { i18n } = useTranslation();
  const classes = rfidStyle();
  const theme = useSelector(getTheme);
  return (
    <>
      <Typography
        className={classes.firstCardMessage}
        style={{
          color: theme.navigationSelectedColor,
        }}
      >
        {isFirstOrder
          ? i18n.t('rfid_first_card_message')
          : i18n.t('rfid_confirm_address')}
      </Typography>
      <Typography
        variant='subtitle1'
        component='span'
        color='textPrimary'
        className={classes.addressConfirmationText}
      >
        {i18n.t('rfid_mailing_address_confirmation_message')}
      </Typography>
      <Grid container>
        <Grid
          xs={12}
          className={clsx(classes.paddingBottom8, classes.paddingTop)}
        >
          <Grid className={clsx(classes.paddingBottom8)}>
            <FormLabel htmlFor='card_name' className={classes.labelM}>
              {i18n.t('name')}
            </FormLabel>
          </Grid>
          <CustomTextField
            id='card_name'
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={mailingDetails.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            variant='filled'
            required
            autoFocus
            placeholder={i18n.t('name')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.paddingBottom8}
          style={{ paddingRight: '6px' }}
        >
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8,
            )}
          >
            {i18n.t('country')}
          </Typography>
          <button
            className={clsx(
              classes.drodDownButton,
              classes.paddingTop,
              classes.labelB2,
            )}
            onClick={handleCountryDropdown}
          >
            <Grid
              container
              item
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                {Country.getCountryByCode(mailingDetails.isoCode || 'CA').name}
                {countryDropdownVisible && (
                  <div className={clsx(classes.dropDownMenu)}>
                    {countryDropdown()}
                  </div>
                )}
              </Grid>
              <Grid item alignItems='center'>
                <img
                  src={DropdownIcon}
                  alt=''
                  className={classes.displayBlock}
                />
              </Grid>
            </Grid>
          </button>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.paddingBottom8}
          style={{ paddingLeft: '6px' }}
        >
          <Typography
            className={clsx(
              classes.labelM,
              classes.paddingTop,
              classes.paddingBottom8,
            )}
          >
            {i18n.t('state_province')}
          </Typography>
          <button
            className={clsx(
              classes.drodDownButton,
              classes.paddingTop,
              classes.labelB2,
            )}
            onClick={handleProvinceDropdown}
          >
            <Grid
              container
              item
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                {mailingDetails.province || (
                  <>
                    <Typography
                      className={classes.labelB2}
                      style={{ color: '#B0B8C1' }}
                    >
                      {i18n.t('state')}
                    </Typography>
                  </>
                )}
                {provinceDropdownVisible && (
                  <div className={clsx(classes.dropDownMenu)}>
                    {provinceDropdown()}
                  </div>
                )}
              </Grid>
              <Grid item>
                <img
                  src={DropdownIcon}
                  alt=''
                  className={classes.displayBlock}
                />
              </Grid>
            </Grid>
          </button>
        </Grid>
        <Grid
          item
          xs={12}
          className={clsx(classes.paddingBottom8, classes.paddingTop)}
        >
          <Grid className={clsx(classes.paddingBottom8)}>
            <FormLabel htmlFor='address' className={classes.labelM}>
              {i18n.t('address')}
            </FormLabel>
          </Grid>
          <CustomTextField
            id='address'
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={mailingDetails.street}
            onChange={(e) => handleInputChange('street', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('address')}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingBottom8}>
          <CustomTextField
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={mailingDetails.unit}
            onChange={(e) => handleInputChange('unit', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('rfid_unit_placeholder')}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingRight: '6px' }}>
          <Grid className={clsx(classes.paddingTop, classes.paddingBottom8)}>
            <FormLabel htmlFor='city' className={classes.labelM}>
              {i18n.t('city')}
            </FormLabel>
          </Grid>
          <CustomTextField
            id='city'
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={mailingDetails.city}
            onChange={(e) => handleInputChange('city', e.target.value)}
            variant='filled'
            required
            placeholder={i18n.t('city')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '6px' }}>
          <Grid className={clsx(classes.paddingTop, classes.paddingBottom8)}>
            <FormLabel htmlFor='zip_postal_code' className={classes.labelM}>
              {i18n.t('zip_postal_code')}
            </FormLabel>
          </Grid>
          <CustomTextField
            id='zip_postal_code'
            className={clsx(classes.inputWrapper, classes.labelB2)}
            fullWidth
            value={mailingDetails.zipCode}
            onChange={(e) =>
              handleInputChange('zipCode', e.target.value.toUpperCase())
            }
            variant='filled'
            required
            placeholder={i18n.t('zip_postal_code')}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      </Grid>
    </>
  );
};
