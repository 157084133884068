import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChargerDtoV2 } from '../dto/charger.dto';
import { useTranslation } from 'react-i18next';
import { getIdleRate } from '../charger-pricing/utils';

const useStyles = makeStyles(() => ({
  sectionText: {
    color: '#4E5968',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    paddingLeft: '2px',
  },
  listHead: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
  },
  list: {
    marginTop: '32px',
    marginBottom: '8px',
  },
  gracePeriod: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#4E5968',
  },
  discountContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  active: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#202223',
  },
  discountText: {
    color: '#6B7684',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'right',
    textDecorationLine: 'line-through',
  },
}));

interface IProps {
  charger: ChargerDtoV2;
  hasUserDiscount: boolean;
}

const IdleRate = ({ charger, hasUserDiscount }: IProps) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  return (
    <>
      {!!charger.currentPrice.idleRate && (
        <Grid container className={classes.list}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      {!!charger.currentPrice.idleRate && (
        <Grid container>
          <span
            className={classes.sectionText}
            style={{
              fontWeight: '500',
              width: '100%',
              display: 'flex',
            }}
          >
            <Grid item xs={6}>
              <Typography className={classes.listHead}>
                {i18n.t('receipt_idling')}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.discountContainer}>
              <Typography className={classes.active}>
                {`${getIdleRate(
                  charger.currentPrice.idleRate,
                  charger.currentPrice.currency,
                )}`}
              </Typography>
              {hasUserDiscount && (
                <Typography className={classes.discountText}>
                  {`${getIdleRate(
                    charger.currentPrice.priceBeforeAdjustment.idleRate,
                    charger.currentPrice.currency,
                  )}`}
                </Typography>
              )}
            </Grid>
          </span>
          <Grid item xs={8} style={{ paddingLeft: 2 }}>
            <Typography className={classes.gracePeriod}>
              {i18n.t('idling_message')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default IdleRate;
