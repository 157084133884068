import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Country, State } from 'country-state-city';
import clsx from 'clsx';
import {
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { PaymentContext } from '../../hooks/usePayment';
import CustomCheckbox from '../ui-components/checkbox.component';
import { CardElement } from '../../stores/types/card-element.interface';
import { CARD_ELEMENT_OPTIONS } from './styles';
import { errorInfoIcon, lockIcon } from '../../assets/icons/icons';

import CustomTextField from '../react-native-material-wrapper/text-field.component';
import Dropdown from '../ui-components/drop-down/Dropdown.component';
import { useAuth } from '../../hooks/useAuth';
import Tippy from '@tippyjs/react';
import { useUserPreference } from '../../hooks/useUserPreference';
import { ErrorRounded } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  EditPaymentPayload,
  EditPaymentRequestBody,
} from '../../stores/types/payment.interface';
import { getAllCountries, sortCountries } from './utils';
import CustomButton from '../react-native-material-wrapper/button.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';

export const useStyles = makeStyles((theme) => ({
  paddingBottom8: {
    paddingBottom: '8px',
  },
  dropDownSelectedItem: {
    backgroundColor: '#F2F4F6',
    color: '#18A0D7',
    borderRadius: '0.25rem',
  },
  dropdownMenuItem: {
    minWidth: '110px',
    padding: 16,
    cursor: 'pointer',
    borderRadius: '4px',
    color: '#4e5968',
  },
  dropdownMenuItemHover: {
    '&:hover': {
      backgroundColor: '#F7F7F7',
    },
  },
  dropDownMenu: {
    backgroundColor: '#fff',
    position: 'absolute' as 'absolute',
    display: 'block',
    listStyleType: 'none',
    left: 0,
    marginTop: 16,
    borderRadius: '4px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '30rem',
    overflow: 'auto',
    zIndex: 1,
  },
  labelM: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  displayBlock: {
    display: 'block',
  },
  paddingTop: {
    paddingTop: 8,
  },
  drodDownButton: {
    background: '#F2F4F6',
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    padding: '12px 8px 12px 16px',
    cursor: 'pointer',
    border: 0,
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  container: {
    padding: '24px 16px 0px',
    background: '#FFF',
    marginBottom: '90px',
  },
  Mb16: {
    marginBottom: '16px',
  },
  Mb8: {
    marginBottom: '8px',
  },
  PL8: {
    paddingLeft: '8px',
  },
  PR8: {
    paddingRight: '8px',
  },
  white: {
    color: '#FFF',
  },
  darkGray: {
    color: '#202223',
  },
  font14: {
    fontSize: 14,
  },
  py12px16: {
    padding: '12px 16px',
  },
  bgDarkGray: {
    backgroundColor: '#202223',
  },
  roundBorder: {
    borderRadius: '8px',
  },
  weight500: {
    fontWeight: 500,
  },
  w235: {
    width: 235,
  },
  fInter: {
    fontFamily: 'Inter',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '24px',
    color: '#202223',
    marginBottom: '8px',
  },
  cardInput: {
    borderRadius: 4,
    'box-sizing': 'border-box',
    height: 48,
    padding: '12px 16px',
    background: '#F2F4F6',
    border: '2px solid transparent',
  },
  errorLabel: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#E53935',
  },
  inputWrapper: {
    '& .MuiFilledInput-input': {
      height: '48px',
      padding: '12px 16px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#F2F4F6',
      borderRadius: '4px',
      fontFamily: 'Inter',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '4px',
    },
    '&::placeholder': {
      color: '#B0B8C1',
    },
  },
  labelB2: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    fontStyle: 'normal',
    color: '#202223',
  },
  Mb: {
    marginBottom: '16px',
  },

  errorBorder: {
    border: '2px solid #E53935',
  },

  labelPosition: {
    position: 'absolute',
    top: '50%',
    right: '14px',
    color: '#E53935',
    transform: 'translate(0%, -50%)',
  },
  relative: {
    position: 'relative',
  },
  paymentErrorContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '16px',
    background: '#FDEDED',
  },
  paymentErrorMessage: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#202223',
  },
  errorStyle: {
    border: '2px solid #E53935',
    backgroundColor: '#F2F4F6',
    borderRadius: '4px',
    '& .MuiFilledInput-root': {
      backgroundColor: '#F2F4F6',
    },
  },
  labelSMediumG6: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Inter',
    color: '#4E5968',
  },
  actionBtnWrapper: {
    padding: 16,
    maxWidth: '425px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#fff',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    boxSizing: 'border-box',
    '& button': {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
      display: 'flex',
      padding: '12px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
    },
  },
  addLaterBtn: {
    borderRadius: '4px',
    border: '1px solid #D1D6DB',
    gap: '10px',
    backgroundColor: '#FFFFFF',
    color: '#202223',
    textTransform: 'none',
  },
}));

const PaymentCardForm = ({ onClickBack }) => {
  const classes = useStyles();
  const theme = useSelector(getTheme);

  const [cardNumber, setCardNumber] = useState<CardElement>();
  const [cardExpiry, setCardExpiry] = useState<CardElement>();
  const [cardCVV, setCardCVV] = useState<CardElement>();

  const auth = useAuth();
  const paymentConsumer = useContext(PaymentContext);

  const cityRef = useRef(null);
  const cardNumberRef = useRef(null);

  const {
    payments,
    handleSavePayment,
    isPrimary,
    setIsPrimary,
    addQueryResult,
    errorMsg,
    editMode,
    selectedPayment,
    updateQueryResult,
    handleEditPayment,
  } = paymentConsumer;

  const { given_name, family_name, address, email, phone_number } =
    auth?.user?.attributes || {};

  const billing_address = editMode
    ? {
        name: selectedPayment?.billingDetails?.name || '',
        line1: selectedPayment?.billingDetails?.address.line1 || '',
        line2: selectedPayment?.billingDetails?.address.line2 || '',
        city: selectedPayment?.billingDetails?.address.city || '',
        state: selectedPayment?.billingDetails?.address.state || '',
        country: selectedPayment?.billingDetails?.address.country || 'CA',
        postal_code: selectedPayment?.billingDetails?.address.postalCode || '',
      }
    : {
        name: `${given_name || ''} ${family_name || ''}`.trim(),
        line1: address?.addressLine1 || '',
        line2: address?.addressLine2 || '',
        country: address?.isoCode || 'CA',
        state: address?.isoCode ? address?.province : '',
        city: address?.isoCode ? address?.city : '',
        postal_code: address?.isoCode ? address?.postalCode : '',
      };

  const [billingDetails, setBillingDetails] = useState({ ...billing_address });
  const [expiryDate, setExpiryDate] = useState('');
  // Default is true as we set CA as deafult country
  const [countryHasState, setCountryHasState] = useState(true);
  const { i18n } = useTranslation();

  const { userIsNotSelectedForPayGo } = useUserPreference();

  useEffect(() => {
    // First payment method is being added - make it primary payment method
    if (!payments?.payload?.length) {
      setIsPrimary(true);
    } else {
      // While editing payment method - we would display existing value
      editMode && setIsPrimary(selectedPayment.isDefault);
    }
  }, [payments, setIsPrimary]);

  useEffect(() => {
    // Scroll to top by default
    cardNumberRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  const cardNumberChange = (event) => {
    setCardNumber(event);
  };

  const cardExpiryChange = (event) => {
    setCardExpiry(event);
  };

  const cardCVVChange = (event) => {
    setCardCVV(event);
  };

  // Needed as separate function in order to avoid validation while user inputs the expiration date
  const isFullExpiryDateEntered = useMemo(() => {
    return expiryDate.length === 5;
  }, [expiryDate]);

  // Validation rules:
  // input year > current year in UTC -> valid
  // input year = current year in UTC -> check input month >= current month in UTC - valid
  // all other cases - invalid
  const isExpiryDateValid = useMemo(() => {
    if (isFullExpiryDateEntered) {
      const date = expiryDate.split('/');
      const year = Number(`20${date[1]}`);
      // In JS month starts from 0
      const month = Number(date[0]) - 1;
      const today = new Date();
      return year > today.getUTCFullYear()
        ? true
        : year === today.getUTCFullYear()
        ? month >= today.getUTCMonth()
        : false;
    }
    return false;
  }, [expiryDate]);

  // Formats card expiry input as user types
  const formatCardExpiryDate = (event: any) => {
    const code = event.keyCode;
    // allow backspace
    const allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/', // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/', // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2', // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0', // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '', // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/', // Prevent entering more than 1 `/`
      );
    setExpiryDate(event.target.value);
  };

  const isButtonValid = () => {
    return editMode
      ? isFullExpiryDateEntered &&
          isExpiryDateValid &&
          billingDetails?.name &&
          billingDetails?.line1 &&
          billingDetails?.country &&
          // if countryHasState : true we need billingDetails?.state value to enable save btn
          (countryHasState ? billingDetails?.state : true) &&
          billingDetails?.city &&
          billingDetails?.postal_code
      : cardNumber?.complete &&
          cardExpiry?.complete &&
          cardCVV?.complete &&
          billingDetails?.line1 &&
          billingDetails?.city &&
          billingDetails?.country &&
          billingDetails?.name &&
          billingDetails?.postal_code &&
          // if countryHasState : true we need billingDetails?.state value to enable save btn
          (countryHasState ? billingDetails?.state : true);
  };

  const cardReady = (element) => {
    element.update({ placeholder: i18n.t('card_number') });
  };

  const handleInputChange = (key: string, value: string) => {
    setBillingDetails({ ...billingDetails, [key]: value });
  };

  const onClickCountryDropdown = (ele) => {
    // Get states based on the country selected
    const states = State.getStatesOfCountry(ele.isoCode || 'CA');
    // set state -> false if no state / province for selected country
    setCountryHasState(states?.length > 0 ? true : false);
    setBillingDetails({ ...billingDetails, country: ele.isoCode, state: '' });
  };

  const onClickProvinceDropdown = (ele) => {
    // This if helps add country to user object when user keeps default country option of dropdown
    setBillingDetails({ ...billingDetails, state: ele.isoCode });
  };

  const onSave = () => {
    const { name, ...rest } = billingDetails;
    const billing_details = {
      address: rest,
      email: email,
      name: name,
      phone: phone_number,
    };
    handleSavePayment(billing_details);
  };

  const onUpdate = () => {
    const { name, ...address } = billingDetails;
    const editPaymentPayload: EditPaymentRequestBody = {
      billingDetails: {
        name: name,
        phone: phone_number,
        email: email,
        address: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          country: address.country,
          postalCode: address.postal_code,
        },
      },
      expiredDate: expiryDate || selectedPayment.expiredDate,
      isDefault: isPrimary,
    };
    const payload: EditPaymentPayload = {
      body: editPaymentPayload,
      id: selectedPayment.paymentMethodId,
    };
    handleEditPayment(payload);
  };

  const showExpiryDateError =
    cardExpiry?.error?.code || (isFullExpiryDateEntered && !isExpiryDateValid);
  /* PayGo MVP user - show new failure msg */
  /* Show payment error / failure msg */
  const renderPaymentErrorMsg = () => {
    errorMsg && window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
      errorMsg && (
        <Grid container className={classes.paymentErrorContainer}>
          <Grid item xs={1}>
            <ErrorRounded
              style={{ color: '#E53935', width: '24px', height: '24px' }}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.paymentErrorMessage}>
              {i18n.t(errorMsg)}
            </Typography>
          </Grid>
        </Grid>
      )
    );
  };

  const isDisabled = useMemo(() => {
    return (
      addQueryResult.isLoading ||
      updateQueryResult?.isLoading ||
      !isButtonValid()
    );
  }, [addQueryResult, updateQueryResult, isButtonValid]);

  return (
    <>
      {!editMode && renderPaymentErrorMsg()}
      <Grid className={classes.container}>
        <Grid container>
          <Grid
            item
            xs={12}
            className={cardNumber?.error?.code ? classes.Mb8 : classes.Mb16}
          >
            <Typography className={classes.formLabel} ref={cardNumberRef}>
              {i18n.t('card_number')}
            </Typography>
            {editMode && (
              <div>
                <Typography className={classes.labelB2}>
                  {`Ending in ${selectedPayment.last4}`}
                </Typography>
              </div>
            )}
            {!editMode && (
              <div
                className={clsx({
                  [classes.cardInput]: true,
                  [classes.relative]: true,
                  [classes.errorBorder]: cardNumber?.error?.code,
                })}
                ref={cardNumberRef} // Attach the ref here
              >
                <CardNumberElement
                  onChange={cardNumberChange}
                  options={CARD_ELEMENT_OPTIONS}
                  onReady={cardReady}
                  onFocus={() => {
                    cardNumberRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                  }}
                />
                <Grid className={classes.labelPosition}>
                  {cardNumber?.error?.code ? (
                    errorInfoIcon()
                  ) : (
                    <Tippy
                      content={
                        <span
                          className={clsx(
                            classes.font14,
                            classes.weight500,
                            classes.fInter,
                          )}
                        >
                          {i18n.t('all_payment_method_information')}
                        </span>
                      }
                      arrow
                      allowHTML
                      placement='top'
                      maxWidth='235px'
                      offset={[-92, 10]}
                      className={clsx(
                        classes.font14,
                        classes.bgDarkGray,
                        classes.white,
                        classes.py12px16,
                        classes.roundBorder,
                        classes.weight500,
                        classes.w235,
                      )}
                    >
                      {lockIcon()}
                    </Tippy>
                  )}
                </Grid>
              </div>
            )}
          </Grid>
          {!editMode && cardNumber?.error?.code && (
            <Grid item xs={12} className={classes.Mb16}>
              <Typography className={classes.errorLabel}>
                {i18n.t('enter_valid_card_number')}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            className={
              cardExpiry?.error?.code
                ? clsx(classes.Mb8, classes.PR8)
                : clsx(classes.Mb16, classes.PR8)
            }
          >
            <Typography className={classes.formLabel}>
              {i18n.t('expiration_date')}
            </Typography>
            {!editMode && (
              <div
                className={clsx({
                  [classes.cardInput]: true,
                  [classes.relative]: true,
                  [classes.errorBorder]: cardExpiry?.error?.code,
                })}
              >
                <CardExpiryElement
                  onChange={cardExpiryChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
                {cardExpiry?.error?.code && (
                  <Grid className={classes.labelPosition}>
                    {errorInfoIcon()}
                  </Grid>
                )}
              </div>
            )}
            {editMode && (
              <CustomTextField
                value={expiryDate}
                onKeyUp={(e) => formatCardExpiryDate(e)}
                onChange={(e) => setExpiryDate(e.target.value)}
                placeholder={i18n.t('MM/YY')}
                fullWidth
                variant='filled'
                required
                error={isFullExpiryDateEntered && !isExpiryDateValid}
                className={clsx(classes.inputWrapper, classes.labelB2, {
                  [classes.errorStyle]:
                    isFullExpiryDateEntered && !isExpiryDateValid,
                })}
                inputProps={{
                  maxLength: 5,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: isFullExpiryDateEntered &&
                    !isExpiryDateValid && (
                      <InputAdornment position='end'>
                        {errorInfoIcon()}
                      </InputAdornment>
                    ),
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            className={
              cardExpiry?.error?.code
                ? clsx(classes.Mb8, classes.PL8)
                : clsx(classes.Mb16, classes.PL8)
            }
          >
            <Typography className={classes.formLabel}>
              {i18n.t('cvv')}
            </Typography>
            {editMode && (
              <div>
                <Typography className={classes.labelB2}>***</Typography>
              </div>
            )}
            {!editMode && (
              <div className={classes.cardInput}>
                <CardCvcElement
                  onChange={cardCVVChange}
                  options={CARD_ELEMENT_OPTIONS}
                />
              </div>
            )}
          </Grid>

          {showExpiryDateError && (
            <Grid item xs={12} className={classes.Mb16}>
              <Typography className={classes.errorLabel}>
                {i18n.t('enter_valid_expiry')}
              </Typography>
              <Typography className={classes.errorLabel}>
                {i18n.t('payment_can_not_be_added')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.Mb16}>
            <Grid className={classes.Mb8}>
              <FormLabel htmlFor='name_on_card' className={classes.formLabel}>
                {i18n.t('name_on_card')}
              </FormLabel>
            </Grid>

            <CustomTextField
              id='name_on_card'
              className={clsx(classes.inputWrapper, classes.labelB2)}
              fullWidth
              value={billingDetails?.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              variant='filled'
              required
              placeholder={i18n.t('name_on_card')}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} className={classes.Mb16}>
            <Grid className={classes.Mb8}>
              <FormLabel
                htmlFor='billing_address'
                className={classes.formLabel}
              >
                {i18n.t('billing_address')}
              </FormLabel>
            </Grid>
            <CustomTextField
              id='billing_address'
              className={clsx(
                classes.inputWrapper,
                classes.labelB2,
                classes.Mb8,
              )}
              fullWidth
              value={billingDetails?.line1}
              onChange={(e) => handleInputChange('line1', e.target.value)}
              variant='filled'
              required
              placeholder={i18n.t('l_address_line_one')}
              InputProps={{
                disableUnderline: true,
              }}
            />
            <CustomTextField
              className={clsx(classes.inputWrapper, classes.labelB2)}
              fullWidth
              value={billingDetails?.line2}
              onChange={(e) => handleInputChange('line2', e.target.value)}
              variant='filled'
              required
              placeholder={`${i18n.t('l_address_line_two')} (${i18n.t(
                'optional',
              )})`}
              label={i18n.t('l_address_line_two')}
              InputLabelProps={{ style: visuallyHidden, 'data-shrink': 'true' }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.Mb16, classes.PR8)}>
            <Typography className={classes.formLabel}>
              {i18n.t('country')}
            </Typography>
            <Dropdown
              value={
                Country.getCountryByCode(billingDetails?.country || 'CA').name
              }
              onClick={onClickCountryDropdown}
              options={sortCountries(getAllCountries())}
              placeholder={i18n.t('country')}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.Mb16, classes.PL8)}>
            <Typography className={classes.formLabel}>
              {i18n.t('state_province')}
            </Typography>
            <Dropdown
              placeholder={i18n.t('state_province')}
              value={billingDetails?.state}
              onClick={onClickProvinceDropdown}
              options={State.getStatesOfCountry(
                billingDetails?.country || 'CA',
              )}
            />
          </Grid>

          <Grid item xs={6} className={clsx(classes.Mb16, classes.PR8)}>
            <Grid className={classes.Mb8}>
              <FormLabel htmlFor='city' className={classes.formLabel}>
                {i18n.t('city')}
              </FormLabel>
            </Grid>
            <CustomTextField
              id='city'
              className={clsx(classes.inputWrapper, classes.labelB2)}
              fullWidth
              value={billingDetails?.city}
              onChange={(e) => handleInputChange('city', e.target.value)}
              variant='filled'
              required
              placeholder={i18n.t('city')}
              InputProps={{ disableUnderline: true }}
              inputRef={cityRef} // Assign the ref to the input field
              onFocus={() => {
                cityRef.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }}
            />
          </Grid>
          <Grid item xs={6} className={clsx(classes.Mb16, classes.PL8)}>
            <Grid className={classes.Mb8}>
              <FormLabel
                htmlFor='zip_postal_code'
                className={classes.formLabel}
              >
                {i18n.t('zip_postal_code')}
              </FormLabel>
            </Grid>
            <CustomTextField
              id='zip_postal_code'
              className={clsx(classes.inputWrapper, classes.labelB2)}
              fullWidth
              value={billingDetails?.postal_code}
              onChange={(e) => handleInputChange('postal_code', e.target.value)}
              variant='filled'
              required
              placeholder={i18n.t('zip_postal_code')}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  color='primary'
                  checked={isPrimary}
                  onChange={() => setIsPrimary(!isPrimary)}
                  name='add_payment'
                  disabled={
                    editMode
                      ? payments?.payload?.length === 1 ||
                        selectedPayment.isDefault
                      : !payments?.payload?.length
                  }
                  style={{ paddingLeft: '12px' }}
                />
              }
              label={i18n.t('primary_payment_method')}
              sx={{
                '& .MuiTypography-root': {
                  // Apply custom styles to the label typography
                  fontWeight: 400,
                  color: '#202223',
                  fontFamily: 'Inter',
                  fontSize: 14,
                },
              }}
            />
          </Grid>
        </Grid>
        {!editMode && (
          <>
            <Divider
              component='div'
              style={{
                margin: '16px 0px',
                background: '#F5F5F5',
              }}
            />
            <Grid item xs={12} style={{ display: 'flex' }}>
              <InfoIcon style={{ color: '#4E5968', paddingRight: '8px' }} />
              <Typography className={classes.labelSMediumG6}>
                {i18n.t('add_payment_temp_hold_msg')}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <div className={classes.actionBtnWrapper}>
        <CustomButton
          variant='contained'
          style={{
            gap: '10px',
            color: isDisabled ? '#D1D6DB' : '#FFFFFF',
            backgroundColor: isDisabled
              ? '#F2F4F6'
              : theme.navigationSelectedColor,
          }}
          onClick={editMode ? onUpdate : onSave}
          isDisabled={isDisabled}
          loading={true}
        >
          {addQueryResult.isLoading || updateQueryResult?.isLoading ? (
            <LoadingDots />
          ) : (
            i18n.t('save')
          )}
        </CustomButton>
        <CustomButton
          variant='outlined'
          className={classes.addLaterBtn}
          onClick={onClickBack}
        >
          {editMode
            ? i18n.t('update_payment_later')
            : i18n.t('add_payment_later')}
        </CustomButton>
      </div>
    </>
  );
};

export default PaymentCardForm;
