import { useTranslation } from 'react-i18next';
import { RuleTypes } from '../../../stores/types/price.interface';
import { ChargerDtoV2 } from '../dto/charger.dto';
import PricingDetails from './pricing-details.component';
import PricingList from './pricing-list.component';
import TieredPricing from './tiered-pricing.component';

interface ChargerPricingInterface {
  charger: ChargerDtoV2;
  hasUserDiscount: boolean;
}

const ChargerPricing = ({
  charger,
  hasUserDiscount,
}: ChargerPricingInterface) => {
  const { i18n } = useTranslation();

  const { type, dayPrices } = charger?.priceSchedule;

  const renderFreeDayPrice = () => (
    <PricingList
      column1={i18n.t('charging')}
      column2={i18n.t('free')}
      hasUserDiscount={hasUserDiscount}
      active
    />
  );

  const renderPriceView = () => {
    switch (type) {
      case RuleTypes.FREE:
        return renderFreeDayPrice();
      case RuleTypes.TIERED:
        return (
          <TieredPricing charger={charger} hasUserDiscount={hasUserDiscount} />
        );
      default:
        return dayPrices ? (
          <PricingDetails charger={charger} hasUserDiscount={hasUserDiscount} />
        ) : (
          renderFreeDayPrice()
        );
    }
  };

  return <>{renderPriceView()}</>;
};

export default ChargerPricing;
