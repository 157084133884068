import {
  DiscountTypes,
  PricingType,
  RuleTypes,
  ScheduleType,
} from '../../../stores/types/price.interface';

export interface ConnectorV2 {
  portId: string;
  connectorTypes: string[];
  status: string;
  maxPowerKilowatts: number;
  statusWithoutMaintenance?: string;
  connectorLocations: string[];
}
export interface LocationCompactV2 {
  name: string;
  latitude: number;
  longitude: number;
  address: {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    province: string;
    country: string;
  };
}
export interface PricePeriodV2 {
  unitPrice: number;
  currency: string;
  pricingType: PricingType;
  // Format: HH:mm 24H
  startTime: string;
  // Format: HH:mm 24H
  endTime: string;
  current: boolean;
}
export interface DayPriceV2 {
  day: string;
  pricePeriods: PricePeriodV2[];
}

export interface TierPriceV2 {
  unitPrice: number;
  currency: string;
  pricingType: PricingType;
  duration: number;
  durationUnit: string;
  priceBeforeAdjustment?: {
    idleRate?: number;
    unitPrice?: number;
  };
}

export interface LocationDtoV2 {
  locationId: string;
  name: string;
  latitude: number;
  longitude: number;
  isoCountryCode: string;
  stateOrRegion?: string;
  city?: string;
  streetAddress?: string;
  zipOrPostalCode?: string;
  timeZone: string;
}
export interface ChargerDtoV2 {
  whiteLabellingName: string;
  actualStatus: string;
  chargerId: string;
  name: string;
  type: string;
  model: string;
  imageUrl: string;
  description: string;
  directions: string;
  location: LocationDtoV2;
  currentPrice: {
    currency: string;
    // deprecated field
    flatRate?: number;
    // deprecated field
    ratePerKilowattHour?: number;
    // deprecated field
    ratePerEffectiveHour?: number;
    // deprecated field
    ratePerHourPluggedIn?: number;
    idleRate?: number;
    pricingType?: PricingType;
    unitPrice: number;
    priceBeforeAdjustment?: {
      idleRate?: number;
      unitPrice?: number;
    };
  };
  owner: {
    type: string;
    id: string;
  };
  ports: ConnectorV2[];
  priceSchedule: {
    type: RuleTypes | ScheduleType;
    dayPrices: DayPriceV2[];
    tierPrices: TierPriceV2[];
  };
  userHasAccess: boolean;
  contactInfo: string;
  maintenanceFlag: boolean;
  whiteLabellingId: string;
  isAnyConnectorAvailable: boolean;
  isSingleConnector: boolean;
  siteHostSignUpWebLink?: string;
  testCompany?: string;
  userPriceAdjustment?: {
    type: DiscountTypes;
    value: number;
  };
}

export class ChargerDto {
  accessType: string;
  addressDto?: string;
  autoStartEnabled?: boolean;
  billingType: string;
  configurationKeys?: any;
  connectorDtoList?: any;
  description: string;
  endPointAddress: string;
  endPointAddressNew: string;
  featured: string;
  hours: string;
  iccid?: any;
  icon: string;
  id?: string;
  imageSrc?: string;
  imageUrl?: string;
  imageUrlList?: any;
  imsi?: string;
  lastHeartbeatTimestamp?: Date;
  lastUpdate?: Date;
  lat?: number;
  lng?: number;
  locale?: string;
  locationId?: string;
  maxPower: string;
  membershipPrice?: number;
  model: string;
  parkingMaxHoursDescription: string;
  parkingType: string;
  phone: string;
  plugType: string;
  price: string;
  priceCurrency: string;
  priceDescription: string;
  readyToIntegration: string;
  restrictionDescription: string;
  shortAddress: string;
  siteId: string;
  spotDescription: string;
  stationId: string;
  status: string;
  strictPrice?: number;
  title: string;
  type: string;
  underMaintenance: string;
  displayId: string;
  voltageInfo: string;
  installInfo: string;
  whiteLabelSiteId?: string;
  whiteLabelOperatorId?: string;
  whiteLabelNetworkId?: string;
  priceDetails?: ChargerDetail;
  userAccessible?: boolean;
}
export interface ActiveSessionLineItems {
  periodFrom: string;
  periodTo: string;
  accumulatedPrice: number;
}

export interface CostBreakdownItem {
  periodStart: string;
  periodEnd: string;
  periodCost: number;
  currency: string;
  periodEnergyKilowattHours: number;
  pricingType: string;
  unitPrice: number;
}

export interface RunningTotals {
  cost: number;
  currency: string;
  costBreakdown: CostBreakdownItem[];
  consumedEnergyKilowattHours: number;
  pluggedInTimeSeconds: number;
  effectiveChargingTimeSeconds: number;
}

export interface Session {
  sessionId: string;
  chargerId: string;
  portId: string;
  createTime: string;
  completeTime: string;
  status: string;
  owner: {
    type: string;
    id: string;
  };
  transactionId: string;
  failureCode: string;
  startedVia: string;
  runningTotals: RunningTotals;
  idleFeeStatus: string;
  idleFeeStartTime: string;
}

export class PricePeriod {
  price: string;
  pricingType: string;
  priceType: string;
  startTime: string;
  endTime: string;
}

export class DayPrice {
  day: string;
  pricePeriods?: PricePeriod[];
}
export class ChargerDetail {
  scheduleType: string;
  dayPrices?: DayPrice[];
  tieredPriceConditions?: TieredPriceDetail[];
}

export class TieredPriceDetail {
  price: string;
  pricingType: string;
  duration: string;
  tieredUnit: string;
}

export class SessionStartPayload {
  chargerId: string;
  portId: number;
}

export interface SessionStartResponse {
  sessionId: string;
  chargerId: string;
  portId: string;
  createTime: string;
  status: string;
  owner: {
    type: string;
    id: string;
  };
  startedVia: string;
}

export enum IdleFeesStatus {
  NOT_IMPOSED = 'NOT_IMPOSED',
  IMPOSED = 'IMPOSED',
  GRACE_PERIOD = 'GRACE_PERIOD',
}

export enum TestCompanyStatus {
  NOT_FROM_TEST_COMPANY = 'false',
}
