import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { LANGUAGES } from '../../services/translation/i18n';
import { getCurrentLanguage } from '../account/setting/utils';

import { ViewWrapper } from '../ui-components/view-wrapper.component';
import { BackButtonHeader } from '../ui-components/back-button-header.component';
import Button from '../ui-components/button/button';

import {
  checkboxCheckedIcon,
  checkboxUncheckedIcon,
} from '../../assets/icons/icons';

import { languageStyles } from './styles';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';

const Language = () => {
  const auth = useAuth();
  const [language, setLanguage] = useState(
    auth.user?.attributes?.profile?.sitehostDashboardLanguage || LANGUAGES[0],
  );

  const navigate = useNavigate();
  const classes = languageStyles();
  const { i18n } = useTranslation();

  const onClickBack = () => {
    navigate(-1);
  };

  const handleSelect = (e) => {
    setLanguage(e.target.value);
  };

  const itemClass = (lang) =>
    lang === language ? classes.selectedCheckbox : classes.checkBox;

  const udpateLanguage = async () => {
    if (document) {
      document.documentElement.lang = language;
    }
    await auth.updateUserLanguage(language);
    onClickBack();
  };

  const theme = useSelector(getTheme);

  return (
    <ViewWrapper>
      <BackButtonHeader
        onBackArrowClicked={onClickBack}
        title={i18n.t('language')}
      />
      <div className={classes.formWrapper}>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue={language}
          name='radio-buttons-group'
        >
          {LANGUAGES.map((lang: string) => (
            <FormControlLabel
              onClick={(e) => handleSelect(e)}
              className={clsx(
                classes.laguageItem,
                itemClass(lang),
                classes.label,
              )}
              style={{ borderColor: lang === language ? theme.primary : '' }}
              value={lang}
              control={
                <Radio
                  id={lang}
                  checkedIcon={checkboxCheckedIcon(theme.primary)}
                  icon={checkboxUncheckedIcon()}
                  checked={language === lang}
                />
              }
              htmlFor={lang}
              label={i18n.t(getCurrentLanguage(lang))}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSelect(e);
                }
              }}
            />
          ))}
        </RadioGroup>
      </div>
      <Button
        label={i18n.t('save')}
        action={udpateLanguage}
        isDisabled={!language}
      />
    </ViewWrapper>
  );
};

export default Language;
