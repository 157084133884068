export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  NINE: 9,
  TWENTY_FOUR: 24,
  HUNDRED: 100,
};
