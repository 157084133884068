import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Pricing from '../../../assets/icons/Pricing.svg';
import Success from '../../../assets/icons/Success.svg';
import { NUMBER } from '../../../constant/number.constant';
import { getTheme } from '../../../stores/selectors/theme.selector';
import { ConnectorType } from '../../../types/charger-connector-type.enum';
import { ConnectorStatus } from '../../../types/connector-status.enum';
import Bolt from '../../ui-components/bolt.component';
import { ChargerConnectorType } from '../charger-connector/charger-connector-type.component';
import { ChargerTechnicianView } from '../charger-connector/charger-technicianView.component';
import ChargerImage from '../charger-image.component';
import ChargerPricing from '../charger-pricing/charger-pricing.component';
import ChargerSession from '../charger-session/charger-session.component';
import { getAbbreviation } from '../charger-session/utils';
import ChargerStatus from '../charger-status.component';
import { BUTTON_STATUS } from '../charger.component';
import {
  ChargerDtoV2,
  IdleFeesStatus,
  Session,
  TestCompanyStatus,
} from '../dto/charger.dto';
import { capitalizeFirstLetter, sortChargerPorts } from './utils';
import DiscountPill from '../../ui-components/discount-pill.component';
import { useAuth } from '../../../hooks/useAuth';
import { useMemo } from 'react';
import IdleRate from './Idle-rate.component';

const useStyles = makeStyles(() => ({
  logo: {
    display: 'flex',
    padding: '8px 16px 0px  16px',
    flexDirection: 'row-reverse',
  },
  imageContainer: {
    width: 128,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
  },
  leftSection: {
    display: 'flex',
    paddingRight: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '50%',
  },
  inSessionHeader: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inSessionText: {
    color: '#4E5968',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px',
    marginBottom: '8px',
  },
  sectionHeader: {
    color: '#000',
    fontSize: '28px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  sectionLocation: {
    color: '#4E5968',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    marginBottom: '16px',
  },
  sectionText: {
    color: '#4E5968',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    paddingLeft: '2px',
  },
  portContainer: {
    textAlign: 'center',
    padding: 15,
    border: '1px solid #D1D6DB',
    borderRadius: '4%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-start',
  },
  portText: {
    color: '#202223',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    width: '50%',
  },
  kWText: {
    color: '#6B7684',
    fontSize: '10px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '16px',
    paddingLeft: '2px',
  },
  sessionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    marginBottom: '24px',
  },
  heading: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    color: '#202223',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#4E5968',
  },
  connector: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  port3: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '8px',
    width: null,
  },
  port2: {
    justifyContent: null,
    flexDirection: 'column',
    marginBottom: null,
    width: 'calc(50% - 4px)',
  },
  alignTextEnd: {
    fontSize: 16,
    fontFamily: 'Inter',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
    display: 'flex',
    justifyContent: 'end',
  },
  listHead: {
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: '#202223',
  },
  list: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  gracePeriod: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeignt: 400,
    lineHeight: '20px',
    paddingTop: '8px',
    color: '#4E5968',
  },
}));

interface ChargerDetailProps {
  charger: ChargerDtoV2;
  showTechnicianIcon?: boolean;
  buttonStatus: BUTTON_STATUS;
  activeSession: Session;
  transactionId: string;
  onSessionStopped: Function;
  handleConnectorChange?: any;
  selectedConnectorId: null | string;
  transaction?: any;
  isChargingBySelf?: boolean;
  stopCharge: Function;
  loading: boolean;
}

const ChargerDetails = ({
  charger,
  showTechnicianIcon = false,
  buttonStatus,
  activeSession,
  transactionId,
  onSessionStopped,
  handleConnectorChange,
  selectedConnectorId,
  transaction,
  isChargingBySelf = false,
  stopCharge,
  loading,
}: ChargerDetailProps) => {
  const auth = useAuth();
  const theme = useSelector(getTheme);
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { ONE, TWO } = NUMBER;

  const { userPriceAdjustment } = charger;

  const hasUserDiscount = useMemo(
    () => auth.user && userPriceAdjustment,
    [auth, userPriceAdjustment],
  );

  const formatDate = (dateString: string) => {
    return moment(dateString, 'YYYY-MM-DDTHH:mm:ssZ')
      .locale(i18n.language)
      .format('h:mm A, MMM D, YYYY');
  };

  const renderSessionChargerDetail = () => {
    return (
      <Box className={classes.container}>
        {activeSession.idleFeeStatus === IdleFeesStatus.NOT_IMPOSED && (
          <>
            <Box className={classes.leftSection}>
              <ChargerImage charger={charger} size={120} />
            </Box>
            <Box className={classes.rightSection}>
              <span className={classes.inSessionHeader}>{charger.name}</span>
              <span className={classes.inSessionText}>
                {charger?.location?.name}
              </span>
              <Box>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                  <ChargerStatus
                    underMaintenance={charger?.maintenanceFlag}
                    connector={charger.ports.find(
                      (ele) =>
                        ele.status?.toLowerCase() ===
                        ConnectorStatus.SESSION.toLowerCase(),
                    )}
                    userAccessible={charger.userHasAccess}
                    showTechnicianIcon={showTechnicianIcon}
                    numberOfPorts={charger.ports?.length}
                    isChargingBySelf={isChargingBySelf}
                  />
                </Box>
                <ChargerTechnicianView
                  underMaintenance={charger?.maintenanceFlag}
                  showTechnicianIcon={showTechnicianIcon}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const renderSinglePortInfo = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ChargerConnectorType
            connectorType={charger.ports[0].connectorTypes[0] as ConnectorType}
            size={20}
            color={'#4E5968'}
          />
          <span className={classes.sectionText} style={{ color: '#000' }}>
            {getAbbreviation(charger.ports[0].connectorTypes[0])}
          </span>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {charger?.ports[0]?.maxPowerKilowatts && (
            <>
              <Bolt width={20} height={20} color={'#4E5968'} />
              <Box
                style={{
                  display: 'flex',
                  paddingRight: '0px',
                  alignItems: 'baseline',
                }}
              >
                <span className={classes.sectionText} style={{ color: '#000' }}>
                  {charger.ports[0].maxPowerKilowatts.toFixed(1)}
                </span>
                <span className={clsx(classes.sectionText, classes.kWText)}>
                  kW
                </span>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const renderIdleChargerDetail = () => {
    return (
      <Box className={classes.container}>
        <Box className={classes.leftSection}>
          <ChargerImage
            charger={charger}
            showGenericCharger={!charger.userHasAccess}
            size={!charger.userHasAccess ? 124 : 160}
          />
        </Box>
        <Box className={classes.rightSection}>
          <span className={classes.sectionHeader}>{charger.name}</span>
          {(charger.testCompany === TestCompanyStatus.NOT_FROM_TEST_COMPANY ||
            !charger.testCompany) && (
            <span className={classes.sectionLocation}>
              {charger.userHasAccess ? charger?.location?.name : ''}
            </span>
          )}
          {charger.ports?.length === ONE && renderSinglePortInfo()}
          {charger.ports?.length === ONE && (
            <ChargerStatus
              underMaintenance={charger?.maintenanceFlag}
              connector={charger.ports[0]}
              userAccessible={charger.userHasAccess}
              showTechnicianIcon={showTechnicianIcon}
              numberOfPorts={charger.ports?.length}
              isChargingBySelf={isChargingBySelf}
            />
          )}
          <ChargerTechnicianView
            underMaintenance={charger?.maintenanceFlag}
            showTechnicianIcon={showTechnicianIcon}
          />
        </Box>
      </Box>
    );
  };

  const renderSessionCompleted = () => {
    return (
      <div className={classes.sessionContainer}>
        <img src={Success} alt='' />
        <div className={classes.heading}>{i18n.t('session_completed')}</div>
        <span className={classes.text}>
          {i18n.t('completed_at')} {formatDate(transaction?.stopTime)}
        </span>
      </div>
    );
  };

  const renderPortsSelection = () => {
    const portLength = charger?.ports?.length;
    return (
      <Grid
        style={{
          display: 'flex',
          flexDirection: portLength > TWO ? 'column' : 'row',
          flexWrap: portLength > TWO ? 'wrap' : 'nowrap',
          justifyContent: 'space-around',
          width: '100%',
          gap: portLength > TWO ? '0' : '8px',
          paddingTop: '24px',
        }}
        container
        sx={{ spacing: 2 }}
      >
        {sortChargerPorts(charger.ports).map((connector, index) => {
          const isChargerInUse =
            connector.status.toLowerCase() ===
            ConnectorStatus.SESSION.toLowerCase();
          const isConnectorSelected = selectedConnectorId === connector.portId;
          return (
            <Box
              key={index}
              className={`${classes.portContainer} ${
                portLength > TWO ? classes.port3 : classes.port2
              }`}
              style={{
                border: isChargerInUse
                  ? '1px solid #E5E8EB'
                  : isConnectorSelected
                  ? `2px solid ${theme.primary}`
                  : '1px solid #D1D6DB',
                backgroundColor: isChargerInUse
                  ? '#F9FAFB'
                  : isConnectorSelected
                  ? theme.secondary
                  : '',
                cursor: !isChargerInUse ? 'pointer' : 'default',
                borderRadius: '16px',
              }}
              onClick={() => {
                if (!isChargerInUse) {
                  handleConnectorChange(connector.portId);
                }
              }}
              onKeyDown={(event) => {
                if (!isChargerInUse && event.key === 'Enter') {
                  handleConnectorChange(connector.portId);
                }
              }}
              tabIndex={0}
            >
              <div>
                <span
                  className={classes.portText}
                  style={{
                    color: isChargerInUse
                      ? '#6B7684'
                      : isConnectorSelected
                      ? theme.primary
                      : '#202223',
                  }}
                >
                  {`${i18n.t('port')} ${
                    connector.portId
                  } (${capitalizeFirstLetter(
                    connector.connectorLocations[0] || '',
                  )})`}
                </span>
                <Box style={{ padding: '8px 0px 0px 0px' }}>
                  <ChargerStatus
                    underMaintenance={charger?.maintenanceFlag}
                    connector={connector}
                    userAccessible={charger.userHasAccess}
                    showTechnicianIcon={showTechnicianIcon}
                    numberOfPorts={charger.ports?.length}
                    isChargingBySelf={isChargingBySelf}
                  />
                </Box>
              </div>
              <Box
                className={classes.connector}
                style={{
                  display: portLength > TWO ? null : 'flex',
                  width: portLength > TWO ? null : '100%',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: portLength > TWO ? '8px' : null,
                  }}
                >
                  <ChargerConnectorType
                    connectorType={connector.connectorTypes[0] as ConnectorType}
                    size={16}
                    color={'#4E5968'}
                  />
                  <span
                    className={classes.sectionText}
                    style={{ color: isChargerInUse ? '#6B7684' : '#000' }}
                  >
                    {getAbbreviation(connector.connectorTypes[0])}
                  </span>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Bolt width={16} height={16} color={'#4E5968'} />
                  </span>
                  <span
                    className={classes.sectionText}
                    style={{ color: isChargerInUse ? '#6B7684' : '#000' }}
                  >
                    {connector.maxPowerKilowatts.toFixed(1)}
                    <span className={classes.kWText}>kW</span>
                  </span>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Grid>
    );
  };
  return (
    <>
      {transaction?.stopTime
        ? renderSessionCompleted()
        : activeSession
        ? renderSessionChargerDetail()
        : renderIdleChargerDetail()}

      {(activeSession || transactionId) && (
        <ChargerSession
          buttonStatus={buttonStatus}
          activeSession={activeSession}
          transactionId={transactionId}
          onSessionStopped={onSessionStopped}
          charger={charger}
          showTechView={showTechnicianIcon}
          transaction={transaction}
          isChargingBySelf={isChargingBySelf}
          stopCharge={stopCharge}
          loading={loading}
        />
      )}

      {!transaction && (
        <>
          {charger?.ports?.length > ONE &&
            !activeSession &&
            renderPortsSelection()}

          <Grid container style={{ paddingTop: '24px' }}>
            <img src={Pricing} alt='pricing_icon' />
            <span
              className={classes.sectionText}
              style={{
                paddingLeft: '8px',
              }}
            >
              {i18n.t('pricing_label')}
            </span>
            {hasUserDiscount && (
              <DiscountPill userPriceAdjustment={userPriceAdjustment} />
            )}
          </Grid>
          <Grid container>
            <span
              className={classes.sectionText}
              style={{
                fontWeight: '500',
                width: '100%',
              }}
            >
              <ChargerPricing
                charger={charger}
                hasUserDiscount={!!hasUserDiscount}
              />
            </span>
          </Grid>
          <IdleRate charger={charger} hasUserDiscount={!!hasUserDiscount} />
        </>
      )}
    </>
  );
};

export default ChargerDetails;
